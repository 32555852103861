.tableRow {
    border: 1px solid #e8e8e8;
}

.tableCell {
    padding: 1rem;
}

.ongoing {
    color: #125794;
}

.success {
    color: #1b4e29;
}

.failure {
    color: #bc5224;
}

.canceled {
    color: #b88c37;
}

.downloadBtn {
    text-align: start;
    &:hover {
        opacity: 75%;
        text-decoration: underline;
    }
}
