@import "src/styles/consts";

.customModal {
  padding: 0 !important;
  font-size: 14px;
  position: fixed;
  top: 30px;
  right: 48px;
  width: 480px;
  height: 75vh;
  transition: none !important;
  transform: none !important;
}

.customBackdrop {
  display: none;
}

.header {
  padding: 8px 48px;
}

.notisCount {
  font-weight: 300;
  font-size: 16px;
  padding: 2px 5px;
  border-radius: 6px;
  background: rgba(75, 102, 135, 0.15);
}

.customBody {
  min-height: 250px !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 0;
}

.controls {
  position: sticky;
  top: 0;
  background-color: #FFFFFFFF;
  padding: 10px 16px 10px 48px;
  display: flex;
  gap: 16px;
  z-index: $z-1;

  border-bottom: 1px solid rgba(9, 30, 66, 0.08);
}

.controlBtn {
  color: #40A2B5;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    cursor: auto;
    text-decoration: none;
    opacity: 65%;
  }
}

.filterBtn {
  width: 1.5rem;
  color: rgba(44, 64, 90, 0.75);
  &:hover {
    color: rgba(44, 64, 90, 0.95);
  }
}

.notisContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notis {
  width: 100%;
  padding: 8px 8px 8px 4px;
  display: flex;
  gap: 12px;
  align-items: start;
}

.markBtn {
  &:hover {
    opacity: 75%;
  }
}

.checkbox {
  margin-top: 3px;
}

.notisContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notisHead {
  display: flex;
  justify-content: space-between;
}

.notisTime {
  font-size: 12px;
  color: rgba(44, 64, 90, 0.45);
}

.new {
  font-size: 12px;
  color: #59AA61
}

.notisText {
  display: inline-block;
  word-break: break-word;
  max-width: 100%;
  overflow: clip;
}

.notisMaxHeight {
  max-height: 3em;
}

.read {
  background-color: rgb(233, 233, 233);
  opacity: 45%;
}

.more {
  border: none;
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px 4px 0;
  background: none;
  margin-right: auto;

  text-decoration: none;
  color: #40A2B5;

  &:hover {
    color: #528bcd;
  }
}

.productName {
  font-weight: 500;
  margin-right: 4px;
}

.noData {
  padding: 10px;
  margin: auto;
  opacity: 75%;
}
