@import 't4b-core-frontend/src/css/colors';

.button {
    border-radius: 50%; 
    max-width: 30px; 
    max-height: 30px; 
    min-width: 30px; 
    min-height: 30px; 
    color: #cfd4d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cfd4d9;
    &:hover {
        color:#6e757c;
    }
}

.smallButton {
    border-radius: 50%; 
    max-width: 24px; 
    max-height: 24px; 
    min-width: 24px; 
    min-height: 24px; 
    color: #cfd4d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cfd4d9;
    &:hover {
        color:#6e757c;
    }
}

.prompt {
    color: $app-color-text;
    white-space: pre-wrap;
}

.smallQuestion {
    &:hover {
        color: #c9cbd4 !important;
    }
}
