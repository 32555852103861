@import 't4b-core-frontend/src/css/colors';

.cardsContainer {
  background-color: #E5EEFF;
}

.header {
  min-height: 37px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.title {
  font-size: 17px;
  font-weight: 450;
  padding-left: 10px;
  color: white;
  white-space: nowrap;
  margin: 0;
  text-transform: capitalize;
}

.button {
  background: none;
  border: none;
  margin-right: 5px;
  color: white;
  &:hover {
    color: white;
  }
}

.onOffBtn {
  position: absolute;
  top: 8px;
  right: 1.5rem;
}

.wrapperGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
  grid-gap: 15px;
  padding: 0 10px 10px;

  @media (max-width: 1060px) {
    grid-template-columns: repeat(1, minmax(400px, 1fr));
  }
  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
  }
  @media (min-width: 2200px) {
    grid-template-columns: repeat(4, minmax(400px, 1fr));
  }
  @media (min-width: 2900px) {
    grid-template-columns: repeat(5, minmax(400px, 1fr));
  }
}

.wrapperOneGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  padding: 0 10px 10px;
}

.wrapperTwoGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  padding: 0 10px 10px;
}

.cardsColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ruleDisplayBtn {
  background: none;
  margin: 2px 0;
  border: none;
  color: #212529;
  text-align: left;

  &:hover {
    background: rgba(28, 52, 81, 0.07);
    border-radius: 2px;
  }
}

.card {
  box-shadow: 0 0 12px 8px rgba(57, 123, 142, 100)
}

.viewSwitch {
  padding: 2px;
  border-radius: 2px !important;
}

.container {
  max-width: 600px;
}

.periodPicker {
  width: 100%;
}

.summary {
  color: #545454;
  font-size: 14px;
}
