.body {
  position: relative;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px dashed #4B6687;
  border-radius: 8px;
  background-color: #E5EEFF4D;
}

.fileIcon {
  margin-right: 8px;
  width: 1.5rem;
  height: 1.5rem;
}

.dragging {
  background-color: rgba(189, 200, 216, 0.3);
}

.inputLabel {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  text-align: center;
}
