.table {
    width: 100%;
    font-size: 0.8rem;
    border-radius: 8px;
    overflow: hidden;
    border-collapse: collapse;
}

.tableHead {
    font-size: 12px;
    font-weight: normal;
    padding: 1rem;
    color: rgba(44, 64, 90, 0.65);
    border-top: 1px solid #e8e8e8;

    &:first-child {
        border-top-left-radius: 8px;
        border-left: 1px solid #e8e8e8;
    }
    &:last-child {
        border-top-right-radius: 8px;
        border-right: 1px solid #e8e8e8;
    }
}

.noData {
    font-size: 12px;
    font-weight: normal;
    padding-top: .5rem;
    color: rgba(44, 64, 90, 0.65);
}
