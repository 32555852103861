.scheduleBarHeader {
  background-color: #fff;
}

.scheduleBarBody {
  background-color: #fff;
}

.tabName {
  color: #2c405a !important;
  font-weight: 500;
}