.filterToggle {
  color: rgba(44, 64, 90, 0.75) !important;
  &:hover {
    color: rgba(44, 64, 90, 0.95) !important;
  }
}

.badge {
  position: absolute;
  top: 5px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: rgba(44, 64, 90, 0.75) !important;
}

.listItems {
  max-height: 200px;
  overflow-y: scroll;
}

