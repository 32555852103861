.actionTab {
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  word-break: break-word;
  &:hover {
    border: 1px solid rgba(75, 102, 135, 0.80);
  }
  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }
}

.active {
  background-color: #ecf5fd;
}

.description {
  font-size: 0.8rem;
}
