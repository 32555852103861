.container {
    background-color: #fff;
    border-radius: 4px 4px 0 0;
}

.title {
    line-height: 32px;
    font-size: 24px;
    min-width: 50%;
    word-wrap: break-word;
    word-break: break-all;
}

.button {
    padding: 10px;
    min-width: 90px;
}

.disabled {
    background-color: #C8C8C8 !important;
    //opacity: 70%
}
