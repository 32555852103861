@import 't4b-core-frontend/src/css/colors';
@import '/src/styles/_colors.scss';

.chipsContainer {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  overflow: clip;
}

.maxHeight {
  max-height: 72px;
}

.formBlock {
  max-width: 640px;
}

.chip {
  background-color: white;
  border-radius: 2px;
  padding: 1px 0 1px 3px;
  font-size: 12px;
  font-weight: 500;
  word-break: break-word;
}

.deleteBtn {
  border: none;
  border-radius: 0 1px 1px 0;
  margin: -1px 0 -1px 0;
  opacity: 50%;
  &:hover {
    background-color: rgba(249, 216, 216, 0.95);
    color: rgba(255, 0, 0, 100);
  }
}

.viewMore {
  border: none;
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
  background: none;
  margin-left: auto;

  text-decoration: none;
  color: $app-color-light;

  &:hover {
    color: #528bcd;

  }
}

.addButtons {
  display: flex;
  gap: 8px;
  max-height: 42px;
  padding-top: 4px;
}
