.scheduleItem {
  width: 46.2rem;
}

.scheduleItemBody {
  background-color: #fff;
}

.deleteBtn {
  color: white;
  &:hover {
    color: white;
    opacity: 85%;
  }
}

.duplicateBtn {
  color: white;
  &:hover {
    opacity: 85%;
  }
}

.picker {
  width: 9.6rem;
}

.summary {
  color: #545454;
  font-size: 14px;
}