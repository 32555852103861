.pageContainer {
    width: 100%;
    height: 100%;
    padding: 48px;
    overflow-x: scroll;
    overflow-y: scroll;
}

.mainContainer {
    min-width: 1100px;
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
}

.mainTitle {
    font-size: 28px;
    font-weight: 600;
    white-space: nowrap;
    color: #2c405a;
}

.title {
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #2c405a;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.contentBox {
    border-radius: 4px;
    border: 1px solid rgba(212, 212, 212, 0.75);
    background-color: #fff;
}

.mainInfo {
    @media (max-width: 1700px) {
        max-width: 600px;
    }
    @media (max-width: 1950px) {
        max-width: 300px;
    }
    @media (max-width: 2600px) {
        max-width: 800px;
    }
}

.factCard {
    height: 240px;
    max-height: 240px;

    @media (max-width: 1920px) {
        height: 270px;
        max-height: 270px;
    }

    @media (max-width: 1700px) {
        height: 310px;
        max-height: 310px;
    }

    @media (max-width: 1500px) {
        height: 340px;
        max-height: 340px;
    }

    @media (max-width: 1300px) {
        height: 390px;
        max-height: 390px;
    }
}

.newsCard {
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }

    height: 600px;
    max-height: 600px;

    @media (max-width: 1920px) {
        height: 610px;
        max-height: 610px;
    }

    @media (max-width: 1700px) {
        height: 620px;
        max-height: 620px;
    }

    @media (max-width: 1500px) {
        height: 650px;
        max-height: 650px;
    }

    @media (max-width: 1300px) {
        height: 660px;
        max-height: 660px;
    }
}

.link {
    text-decoration: none;
    color: #3cb5dc;
    &:hover {
        color: #3cb5dc;
        text-decoration: underline;
    }
}

.illustration {
    width: 40%;
    z-index: 1;
}

.gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    grid-row-gap: 24px;
    margin-bottom: 24px;
}

.gridWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;

    @media screen and (max-width: 2200px) {
        grid-template-columns: 1fr 3fr;
    }
    @media screen and (max-width: 2900px) {
        grid-template-columns: 2fr 3fr;
    }
}
