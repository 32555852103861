$z-0: 0;
$z-1: 1;
$z-10: 10;
$z-100: 100;
$z-1000: 1000;
$z-1050: 1050; // to avoid overlap with toast containers z-index of 9999
$z-100000: 100000;

.z-0 {
  z-index: $z-0;
}

.z-1 {
  z-index: $z-1;
}

.z-10 {
  z-index: $z-10;
}

.z-100 {
  z-index: $z-100;
}

.z-1000 {
  z-index: $z-1000;
}

.z-1050 {
  z-index: $z-1050;
}

.z-100000 {
  z-index: $z-100000;
}
