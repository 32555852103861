.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    transition: .2s;
    color: #FFFFFF;
    &:hover {
        color: #e03444;
    }
}
